<template>
  <Toast />

  <DataTable :value="toppingsManuData" :show-gridlines="true" responsiveLayout="scroll" :loading="loading" :class="flex"
    rowGroupMode="rowspan" groupRowsBy="rowTitle">
    <template v-if="mode == 'single'">
      <Column field="rowTitle" :style="{ 'min-width': '100px', 'width': '100px' }"></Column>

      <Column v-for="product in toppingsManuProducts" :key="product.product_id" :field="product.name"
        :style="{ 'min-width': '110px', 'width': '120px' }">
        <template #header>
          <p>{{ product.name }}</p>
        </template>

        <template #body="{ data, field }">
          <div class="flex flex-column justify-content-start align-items-center">
            <!-- {{ data["rowTitle"] }} {{ data['order'] }} -->
            <template v-if="data['rowTitle'] == '前次備量'">
              <!-- {{ data[field] }} -->
              <template v-for="d in data[field]" :key="d.id">
                <p v-if="canShowExistingProduct(field, d.manufacture_datetime)">{{ d.product_out_quantity }} {{ d.product_out_uom }} ({{ dayAgo(dayjs(),
                    dayjs(d.manufacture_datetime))
                }}天前)</p>
              </template>
              <div v-if="showMoreExistingProduct.hasOwnProperty(field)">
                <Button v-if="!showMoreExistingProduct[field]" @click="showMoreExistingProduct[field] = true" class="p-1 p-button-text" label="顯示更多"/>
                <Button v-else @click="showMoreExistingProduct[field] = false" class="p-1 p-button-text" label="顯示較少"/>
              </div>
            </template>
            <template v-if="data['rowTitle'] == '庫存' && data['order'] == 1">
              <Button v-if="isSameDay(dayjs(), dayjs(startDate))" icon="pi pi-plus"
                class="p-button-rounded-sm p-button-purpply" @click="selectNewInventory(product)"></Button>
            </template>
            <template v-if="data['rowTitle'] == '庫存' && data['order'] == 2">
              <!-- {{ data[field] }} -->
              <template v-for="d in data[field]" :key="d.id">
                <div class="flex">
                  <Button
                    :label="`${formatDate(d.manufacture_datetime, 'HH:mm')} ${d.product_out_quantity} ${d.product_out_uom}`"
                    class="p-1 p-button-text"></Button>
                  <Button v-show="isSameDay(dayjs(), dayjs(startDate))" icon="pi pi-pencil" class="p-button-text"
                    @click="selectUpdateToppingInventory(d)" />
                </div>
              </template>
            </template>
            <template v-if="data['rowTitle'] == '今日備量' && data['order'] == 1">
              <Button v-if="isSameDay(dayjs(), dayjs(startDate))" icon="pi pi-plus"
                class="p-button-rounded-sm p-button-purpply" @click="selectNewTopping(product)"></Button>
              <!-- {{ data[field] }} -->
            </template>
            <template v-if="data['rowTitle'] == '今日備量' && data['order'] == 2">
              <!-- {{ data[field] }} -->
              <template v-for="d in data[field]" :key="d.id">
                <div class="flex">
                  <Button
                    :label="`${formatDate(d.manufacture_datetime, 'HH:mm')} ${d.product_out_quantity} ${d.product_out_uom}`"
                    class="p-1 p-button-text"></Button>
                  <Button v-show="isSameDay(dayjs(), dayjs(startDate))" icon="pi pi-pencil" class="p-button-text"
                    @click="selectUpdateTopping(d)" />
                </div>
              </template>
            </template>
            <template v-if="data['rowTitle'] == '總計'">
              <div class="flex gap-1">
                <template v-for="(d, k, idx) in data[field]" :key="idx">
                  <span>{{ d }}</span>
                </template>
              </div>
            </template>
          </div>
        </template>
      </Column>
    </template>
    <template v-else-if="mode == 'range'">
      <Column field="rowTitle" :style="{ 'min-width': '100px', 'width': '100px' }">
        <template #body="{ data }">
          {{ (data.rowTitle instanceof Date) ? formatDate(data.rowTitle, "YYYY-MM-DD") : data.rowTitle }}
        </template>
      </Column>
      <Column field="order" :style="{ 'min-width': '60px', 'width': '60px' }">
        <template #body="{ data }">
          <template v-if="data['order'] == 1">
            庫存
          </template>
          <template v-if="data['order'] == 2">
            備量
          </template>
        </template>
      </Column>
      <Column v-for="product in toppingsManuProducts" :key="product.product_id" :field="product.name"
        :style="{ 'min-width': '110px', 'width': '120px' }">
        <template #header>
          <p>{{ product.name }}</p>
        </template>
        <template #body="{ data, field }">
          <!-- {{ data }} -->
          <div class="flex justify-content-center align-items-center gap-1">
            <template v-for="(d, k, idx) in data[field]" :key="idx">
              <span>{{ d }}</span>
            </template>
          </div>
        </template>
      </Column>
    </template>
  </DataTable>

  <!-- 庫存 Dialog -->
  <Dialog header="請輸入庫存數量" v-model:visible="showInventory" :dismissableMask="true" :breakpoints="{ '960px': '96vw' }"
    :style="{ width: '70vw' }" :modal="true" @hide="cleanUp">
    <div class="my-4 gap-2 flex flex-column">
      <template v-if="selectedNewTopping.uom != 'c.c'">
        <div class="flex gap-2 align-items-center">
          <div class="flex">
            <InputNumber v-model="inputInventory" :inputProps="{ 'type': 'tel' }" />
            <span class="p-inputgroup-addon">{{ selectedNewTopping.uom }}</span>
          </div>
          <span>+</span>
          <div class="flex">
            <InputMask v-model="inputLess" mask="9/9" placeholder="2/3" slotChar="n/m" type="tel" />
            <span class="p-inputgroup-addon">{{ selectedNewTopping.uom }}</span>
          </div>
        </div>
      </template>
      <div v-else-if="selectedNewTopping.name == '冬瓜'" class="flex">
        <div class="my-4 flex gap-2">
          <SelectButton v-model="inputInventory" :options="toppingsOptions[selectedNewTopping.name]"
            optionLabel="text" />
          <div v-if="inputInventory != null && inputInventory.text == '自訂'" class="flex">
            <InputNumber v-model="inputInventory.value" :inputProps="{ 'type': 'tel' }" @input="onChange" />
            <span class="p-inputgroup-addon">{{ inputNewBom.uom }}</span>
          </div>
        </div>
      </div>
      <div v-else class="flex">
        <InputNumber v-model="inputInventory" :inputProps="{ 'type': 'tel' }" />
        <span class="p-inputgroup-addon">{{ selectedNewTopping.uom }}</span>
      </div>
      庫存有 {{ inputInventoryAmount }} {{ selectedNewTopping.uom }}
    </div>
    <template #footer>
      <Button class="p-button-secondary" label="新增" :disabled="inputInventoryAmount < 0" @click="submitNewInventory" />
    </template>
  </Dialog>

  <!-- 今日備量 Dialog -->
  <Dialog header="請輸入今日備量" v-model:visible="showNewTopping" :dismissableMask="true" :breakpoints="{ '960px': '96vw' }"
    :style="{ width: '90vw' }" :modal="true" @hide="cleanUp">
    <div class="my-4 flex gap-2">
      <SelectButton v-model="inputNewTopping" :options="toppingsOptions[selectedNewTopping.name]" optionLabel="text" />
      <div v-if="inputNewTopping != null && inputNewTopping.text == '自訂'" class="flex">
        <InputNumber v-model="inputNewTopping.value" :inputProps="{ 'type': 'tel' }" @input="onChange" />
        <span class="p-inputgroup-addon">{{ inputNewBom.uom }}</span>
      </div>
      <!-- <InputNumber v-model="inputNewTopping" :suffix="` ${selectedNewTopping.uom}`"
        :placeholder="`輸入備量 eg. 10 ${selectedNewTopping.uom}`" /> -->
    </div>
    備量 {{ inputNewAmount }} {{ selectedNewTopping.uom }}
    <template #footer>
      <Button class="p-button-secondary" label="新增" :disabled="inputNewTopping == null" @click="submitNewTopping" />
    </template>
  </Dialog>

  <!-- 更新庫存 Dialog -->
  <Dialog header="更新庫存數量" v-model:visible="showUpdateToppingInventory" :dismissableMask="true" :breakpoints="{ '960px': '96vw' }"
    :style="{ width: '70vw' }" :modal="true" @hide="cleanUp">
    <!-- {{ selectedUpdateTopping }} -->
    <p class="mt-4">當前紀錄: {{ selectedUpdateTopping.product_out_name }} {{ selectedUpdateTopping.product_out_quantity }}
      {{ selectedUpdateTopping.product_out_uom }}</p>
    <div class="my-2 flex">
      <InputNumber v-model="inputUpdateQunatity" :inputProps="{ 'type': 'tel' }" :maxFractionDigits="2" />
      <span class="p-inputgroup-addon">{{ selectedUpdateTopping.product_out_uom }}</span>
    </div>
    <template #footer>
      <Button class="p-button-secondary" label="更新" @click="submitUpdate" :disabled="inputUpdateQunatity == null" />
    </template>
  </Dialog>

  <!-- 更新備量 Dialog -->
  <Dialog header="更新備量數量" v-model:visible="showUpdateTopping" :dismissableMask="true" :breakpoints="{ '960px': '96vw' }"
    :style="{ width: '70vw' }" :modal="true" @hide="cleanUp">
    <!-- {{ selectedUpdateTopping }} -->
    <p class="mt-4">當前紀錄: {{ selectedUpdateTopping.product_out_name }} {{ selectedUpdateTopping.product_out_quantity }}
      {{ selectedUpdateTopping.product_out_uom }}</p>
    <div class="my-2 flex">
      <InputNumber v-model="inputUpdateQunatity" :inputProps="{ 'type': 'tel' }" @input="onInputUpdate" />
      <span class="p-inputgroup-addon">{{ selectedUpdateTopping.product_out_uom }}</span>
    </div>
    <hr>
    <div class="my-4 flex gap-2">
      <SelectButton v-model="inputUpdate" :options="toppingsOptions[selectedUpdateTopping.product_out_name]"
        optionLabel="text" @change="onInputUpdate" />
      <div v-if="inputUpdate != null && inputUpdate.text == '自訂'" class="flex">
        <InputNumber v-model="inputUpdate.value" :inputProps="{ 'type': 'tel' }" />
        <span class="p-inputgroup-addon">{{ inputUpdateBom.uom }}</span>
      </div>
    </div>
    更新為 {{ aboutUpdateAmount }} {{ selectedUpdateTopping.product_out_uom }}
    <template #footer>
      <Button class="p-button-secondary" label="更新" @click="submitUpdate" :disabled="(aboutUpdateAmount == 0)" />
    </template>
  </Dialog>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
import { formatDate, isSameDay, dayAgo, listDates } from '@/composable/date'
import {
  getOverviewData,
  getManufacturableProducts,
  postManufactorProducts,
  postConfirmManufacture,
  postLotProperties,
  postManufactureProperties,
  getBoms,
  postCancelManufactureOrder,
} from '@/api'
import { deepClone } from '@/helpers'

const props = defineProps(['startDate', 'endDate', 'mode'])

const toast = useToast()

const loading = ref(false)
const defaultToppingsData = ref({})
const rangeDateList = ref([])
const toppingsOptions = ref({})
const bomData = ref({})
const manufactureOrders = ref([])
const pastManufactureOrders = ref([])
const toppingsManuProducts = ref([])
const showInventory = ref(false)
const showNewTopping = ref(false)
const showUpdateTopping = ref(false)
const showUpdateToppingInventory = ref(false)
const showMoreExistingProduct = ref({})
const moreThanOne = ref(false)
const lessThanOne = ref(false)
const selectedNewTopping = ref({})
const selectedUpdateTopping = ref({})
const inputInventory = ref(null)
const inputLess = ref("0/0")
const inputNewTopping = ref(null)
const inputUpdateQunatity = ref(null)
const inputUpdate = ref(null)
const inputNewToppingDisabled = ref(true)

const toppingsManuData = computed(() => {
  if (props.mode == 'range') {
    let totalG = [{ rowTitle: '總計', order: 1 }, { rowTitle: '總計', order: 2 }]
    let totalList = [..._.cloneDeep(rangeDateList.value), ...totalG]
    let diffDay = 0
    // console.log('toppingsManuData manufactureOrders', manufactureOrders)
    manufactureOrders.value.forEach(inv => {
      try {
        const properties = JSON.parse(inv.properties)
        const propertiesType = _.get(properties, 'type')
        if (propertiesType === 'inventory' || propertiesType === 'new_topping') {
          console.log(inv)
          diffDay = dayAgo(dayjs(inv.manufacture_datetime), dayjs(props.startDate))
          let diffDayIndex = propertiesType === 'inventory' ? 2 * diffDay : 2 * diffDay + 1
          let totalIndex = propertiesType === 'inventory' ? 0 : 1

          if (!Object.keys(totalList[diffDayIndex]).includes(inv.product_out_name)) {
            totalList[diffDayIndex][inv.product_out_name] = { sum: 0, uom: inv.product_out_uom }
          }
          if (!Object.keys(totalG[totalIndex]).includes(inv.product_out_name)) {
            totalG[totalIndex][inv.product_out_name] = { sum: 0, uom: inv.product_out_uom }
          }
          totalList[diffDayIndex][inv.product_out_name].sum += inv.product_out_quantity
          totalG[totalIndex][inv.product_out_name].sum += inv.product_out_quantity
        }
      } catch {
        return
      }
    })

    totalG.forEach(total => {
      Object.keys(total).forEach(key => {
        if (key !== 'rowTitle' && key !== 'order') {
          const sum = total[key].sum
          const [amount, uom] = calBomAmount(sum, key, true)
          total[key].sum = _.round(amount, 2)
          total[key].uom = uom
        }
      })
    })

    return totalList
  } else {

    let list = deepClone(defaultToppingsData.value)
    manufactureOrders.value.forEach(inv => {
      // 只分類 今日 manufactureOrders
      if (isSameDay(dayjs(props.startDate), dayjs(inv.manufacture_datetime))) {
        // console.log(inv.product_out_name, 'inv.properties', inv.properties,)
        // 依照[列]分類，Array [前次備量, 今早庫存, 昨日用量, 今日備量 ...]
        if (inv.properties != "False") {
          if (JSON.parse(inv.properties)["type"] == "inventory") { // 庫存
            if (!Object.keys(list[2]).includes(inv.product_out_name)) {
              list[2][inv.product_out_name] = [inv]
            } else {
              list[2][inv.product_out_name].push(inv)
            }
          } else { // 今日備量
            if (!Object.keys(list[4]).includes(inv.product_out_name)) {
              list[4][inv.product_out_name] = [inv]
            } else {
              list[4][inv.product_out_name].push(inv)
            }

            // 總計數量
            if (inv.product_out_name in list[5]) {
              let sums = list[5][inv.product_out_name]['sum'] + inv.product_out_quantity
              if (!Number.isInteger(sums)) list[5][inv.product_out_name]['sum'] = parseFloat(sums.toFixed(1))
              else list[5][inv.product_out_name]['sum'] = sums
            } else {
              list[5][inv.product_out_name] = { sum: inv.product_out_quantity, uom: inv.product_out_uom }
            }
          }
        } else { // 今日備量
          if (!Object.keys(list[4]).includes(inv.product_out_name)) {
            list[4][inv.product_out_name] = [inv]
          } else {
            list[4][inv.product_out_name].push(inv)
          }

          // 總計數量
          if (inv.product_out_name in list[5]) {
            let sums = list[5][inv.product_out_name]['sum'] + inv.product_out_quantity
            if (!Number.isInteger(sums)) list[5][inv.product_out_name]['sum'] = parseFloat(sums.toFixed(1))
            else list[5][inv.product_out_name]['sum'] = sums
          } else {
            list[5][inv.product_out_name] = { sum: inv.product_out_quantity, uom: inv.product_out_uom }
          }
        }
      } else { }
    })


    pastManufactureOrders.value.forEach(inv => { // 前次備量
      if (inv.properties != "False") {
        if (JSON.parse(inv.properties)["type"] == "new_topping") {
          if (!Object.keys(list[0]).includes(inv.product_out_name)) {
            list[0][inv.product_out_name] = [inv]
          } else {
            list[0][inv.product_out_name].push(inv)
          }
        }
      } else { }
    })
    return list
  }
})
const inputInventoryAmount = computed(() => {
  let amount = 0
  if (Number.isInteger(inputInventory.value)) {
    if (inputInventory.value != null) {
      amount += inputInventory.value
    }
    if (inputLess.value != "") {
      let bits = inputLess.value.split("/")
      if (parseInt(bits[1], 10) == 0) {
        amount += 0
      } else {
        amount += (parseInt(bits[0], 10) / parseInt(bits[1], 10))
      }
    }
  } else {
    console.log('inputLess', inputLess.value)
    if (inputInventory.value != null) {
      // console.log('inputInventory.value', inputInventory.value, calBomAmount(inputInventory.value.value))
      amount += calBomAmount(inputInventory.value.value, selectedNewTopping.value.name)[0]
    }
    if (inputLess.value != "") {
      let bits = inputLess.value.split("/")
      if (parseInt(bits[1], 10) == 0) {
        amount += 0
      } else {
        amount += (parseInt(bits[0], 10) / parseInt(bits[1], 10))
      }
    }
  }
  console.log('inputInventoryAmount', amount)
  return amount
})
const inputNewBom = computed(() => {
  return bomData.value.find((bom) => bom.product_name == selectedNewTopping.value.name)
})
const inputNewAmount = computed(() => {
  if (inputNewTopping.value == null) {
    return 0
  } else {
    return calBomAmount(inputNewTopping.value.value, selectedNewTopping.value.name)[0]
  }
})
const inputUpdateBom = computed(() => {
  return bomData.value.find((bom) => bom.product_name == selectedUpdateTopping.value.product_out_name)
})
const aboutUpdateAmount = computed(() => {
  let amount = 0

  if (inputUpdateQunatity.value != null) {
    amount = inputUpdateQunatity.value
  } else if (inputUpdate.value != null) {
    amount = calBomAmount(inputUpdate.value.value, selectedUpdateTopping.value.product_out_name)[0]
  } else { }

  return amount
})

watch(() => props.startDate, (newVal, oldVal) => {
  fetchTableContents().then(() => { })
})
watch(() => props.endDate, (newVal, oldVal) => {
  fetchTableContents().then(() => { })
})
watch(() => props.mode, (newVal, oldVal) => {
  fetchTableContents()
})

const calBomAmount = (value, selectedToppingName, reverse=false) => {
  console.log('calBomAmount', selectedToppingName)
  let bom = bomData.value.find((bom) => bom.product_name == selectedToppingName)
  let ratio = bom.out / bom.unit
  let amount = reverse ? value / ratio : value * ratio
  // console.log('calBomAmount bom:', bom, 'amount:', amount)
  if (Number.isInteger(amount)) {
    return [amount, bom.uom]
  } else {
    return [parseFloat(amount.toFixed(2)), bom.uom]
  }
}
const getDefaultData = async () => {
  defaultToppingsData.value = await fetch('data/defaultToppings.json').then(res => res.json()).then(d => d)
}
const getToppingsOptions = async () => {
  toppingsOptions.value = await fetch('data/toppingsOptions.json').then(res => res.json()).then(d => d)
}
const getManufactorData = async () => {
  let resp = await getManufacturableProducts()
  toppingsManuProducts.value = resp.data.products
    .filter((prod) => prod.category == '半成品')
    .sort((a, b) => a.product_id - b.product_id)
}
const getBomData = async () => {
  let resp = await getBoms()
  bomData.value = resp.data.boms.map((bom) => {
    return {
      "product_id": bom.product_out_id,
      "product_name": bom.product_out_name,
      "unit": bom.product_consumed[0].quantity,
      "out": bom.product_out_quantity,
      "uom": bom.product_consumed[0].uom
    }
  })
  // console.log('getBomData', bomData)
}
const fetchTableContents = async () => {
  loading.value = true
  let ranges = []
  let dateRanges = listDates(props.startDate, props.endDate, true)
  dateRanges.forEach((date) => {
    ranges.push({ rowTitle: date, order: 1 })
    ranges.push({ rowTitle: date, order: 2 })
  })
  rangeDateList.value = ranges
  // console.log('rangeDateList.value', rangeDateList.value)
  await getManufactorData()
  await getBomData()

  try {
    let resp
    let pastResp
    if (props.mode == 'range') {
      resp = await getOverviewData(
        dayjs(props.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        dayjs(props.endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss")
      )
    } else {
      resp = await getOverviewData(
        dayjs(props.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        dayjs(props.startDate).endOf('day').format("YYYY-MM-DD HH:mm:ss")
      )
      pastResp = await getOverviewData(
        dayjs(props.startDate).startOf('day').subtract(10, 'day').format("YYYY-MM-DD HH:mm:ss"),
        dayjs(props.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss")
      )
      // console.log('pastResp', pastResp)
      pastManufactureOrders.value = pastResp.data.manufacture_orders
    }

    manufactureOrders.value = resp.data.manufacture_orders
  } catch (error) {
    console.log('error', error)
  }
  loading.value = false
}
// 半成品庫存
const submitNewInventory = async () => {
  await postManufactorProducts([
    {
      product_id: selectedNewTopping.value.product_id,
      quantity: inputInventoryAmount.value
    }
  ]).then((resp) => {
    let manu = resp.data.manufacture_orders[0]
    manu.product_required.sort((a, b) => b.product_id - a.product_id)
    return postConfirmManufacture([
      {
        order_id: manu.order_id,
        product_in: [
          {
            product_id: manu.product_required[0].product_id,
            lot_id: manu.product_required[0].lots[0].id,
            quantity: manu.product_required[0].quantity,
          }
        ]
      }
    ])
  }).then((result) => {
    showInventory.value = false
    console.log('result', result)
    if (result.status == 200) {
      toast.add({ severity: 'success', summary: '成功', detail: '半成品庫存回報成功', life: 2000 })
      setTimeout(async () => {
        await postLotProperties(result.data.manufacture_orders[0].lot_id, { type: "inventory" })
        await postManufactureProperties(result.data.manufacture_orders[0].order_id, { type: "inventory" })
        await fetchTableContents()
      }, 1000)
    }
  }).catch((err) => {
    console.log('submitNewTopping error', err)
    toast.add({ severity: 'error', summary: '失敗', detail: '半成品庫存回報失敗', life: 2000 })
    showInventory.value = false
  })
}
// 半成品製作
const submitNewTopping = async () => {
  await postManufactorProducts([
    {
      product_id: selectedNewTopping.value.product_id,
      // quantity: inputNewTopping.value
      quantity: inputNewAmount.value
    }
  ]).then((resp) => {
    let manu = resp.data.manufacture_orders[0]
    manu.product_required.sort((a, b) => b.product_id - a.product_id)
    console.log('resp manu', manu)
    return postConfirmManufacture([
      {
        order_id: manu.order_id,
        product_in: [
          {
            product_id: manu.product_required[0].product_id,
            lot_id: manu.product_required[0].lots[0].id,
            quantity: manu.product_required[0].quantity,
          }
        ]
      }
    ])
  }).then((result) => {
    showNewTopping.value = false
    if (result.status == 200) {
      toast.add({ severity: 'success', summary: '成功', detail: '半成品製作成功', life: 2000 })
      setTimeout(async () => {
        await postLotProperties(result.data.manufacture_orders[0].lot_id, { type: "new_topping" })
        await postManufactureProperties(result.data.manufacture_orders[0].order_id, { type: "new_topping" })
        await fetchTableContents()
      }, 1000)
    }
  }).catch((err) => {
    console.log('submitNewTopping error', err)
    toast.add({ severity: 'error', summary: '失敗', detail: '半成品製作失敗', life: 2000 })
    showNewTopping.value = false
  })
}
// 更新
const submitUpdate = async () => {
  // 1. Call cancel_manufacture_order endpoint
  let resp = await postCancelManufactureOrder(selectedUpdateTopping.value.id)

  // 2. Update Lot properties
  let lastProps = JSON.parse(selectedUpdateTopping.value.properties)
  console.log('lastProps', lastProps)
  await postLotProperties(selectedUpdateTopping.value.lot, { ...lastProps, status: 'cancelled' })
  await postManufactureProperties(selectedUpdateTopping.value.id, { ...lastProps, status: 'cancelled' })

  // 3. Create a new manufacture order
  postManufactorProducts([
    {
      product_id: selectedUpdateTopping.value.product_out_id,
      quantity: aboutUpdateAmount.value
    }
  ]).then((resp) => {
    let manu = resp.data.manufacture_orders[0]
    manu.product_required.sort((a, b) => b.product_id - a.product_id)
    // 4. Confirm the manufacture order with create_datetime in payload
    return postConfirmManufacture([
      {
        order_id: manu.order_id,
        product_in: [
          {
            product_id: manu.product_required[0].product_id,
            lot_id: manu.product_required[0].lots[0].id,
            quantity: manu.product_required[0].quantity,
          }
        ],
      },
    ], selectedUpdateTopping.value.manufacture_datetime)
  }).then((result) => {
    showUpdateTopping.value = false
    showUpdateToppingInventory.value = false
    if (result.status == 200) {
      toast.add({ severity: 'success', summary: '成功', detail: '更新成功', life: 2000 })
      setTimeout(async () => {
        await postLotProperties(result.data.manufacture_orders[0].lot_id, lastProps)
        await postManufactureProperties(result.data.manufacture_orders[0].order_id, lastProps)
        await fetchTableContents()
      }, 1000)
    }
  }).catch((err) => {
    console.log('submitUpdate error', err)
    toast.add({ severity: 'error', summary: '失敗', detail: '更新失敗', life: 2000 })
    showUpdateTopping.value = false
    showUpdateToppingInventory.value = false
  })
}
const canShowExistingProduct = (field, manufacture_datetime) => {
  if (showMoreExistingProduct.value[field]) return true

  const interval = ['粉圓', '草仔粿'].includes(field) ? 'day' : 'week'
  const minDatetime = dayjs().subtract(1, interval)

  const ret = dayjs(manufacture_datetime).isAfter(minDatetime)
  if (!ret) showMoreExistingProduct.value[field] = false
  return ret
}
const selectNewInventory = (prod) => {
  showInventory.value = true
  selectedNewTopping.value = { ...prod }
}
const selectNewTopping = (prod) => {
  showNewTopping.value = true
  selectedNewTopping.value = { ...prod }
}
const selectUpdateTopping = (prod) => {
  showUpdateTopping.value = true
  selectedUpdateTopping.value = { ...prod }
}
const selectUpdateToppingInventory = (prod) => {
  showUpdateToppingInventory.value = true
  selectedUpdateTopping.value = { ...prod }
}
const onInputUpdate = (evt) => {
  console.log('onInputUpdate', evt)
  if (Number.isInteger(evt.value)) {
    inputUpdate.value = null
    inputUpdateQunatity.value = evt.value
  } else {
    inputUpdateQunatity.value = null
  }
}
const cleanUp = () => {
  inputUpdateQunatity.value = null
  inputUpdate.value = null
  selectedNewTopping.value = null
  moreThanOne.value = false
  lessThanOne.value = false
  inputInventory.value = null
  inputLess.value = "0/0"
  if (inputNewTopping.value.text == '自訂') {
    inputNewTopping.value.value = null
  }
  inputNewTopping.value = null
  selectedUpdateTopping.value = {}
}

//
getDefaultData()
getToppingsOptions()

onMounted(async () => {
  await fetchTableContents()
})
</script>

<style lang="scss" scoped>
.p-button-rounded-sm {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
}

.p-column-header-content {
  align-items: center;
}
</style>
